@if (errorList$ | async; as errorList) {
  <div #container aria-labelledby="error-summary-title" class="govuk-error-summary" role="alert" tabindex="-1">
    <h2 class="govuk-error-summary__title" id="error-summary-title">There is a problem</h2>
    <div class="govuk-error-summary__body">
      <ul class="govuk-list govuk-error-summary__list">
        @for (errorItem of errorList; track errorItem) {
          @if (errorItem) {
            <li>
              <p class="highlight-error">{{ errorItem?.message }}</p>

              @if (errorItem?.columns && errorItem?.rows) {
                <govuk-details summary="View details">
                  <p>
                    <span
                      >Check the data in column '{{ errorItem?.columns }}' on rows
                      {{ getRowIndexes(errorItem?.rows).join(', ') }}
                    </span>
                  </p>
                </govuk-details>
              }
            </li>
          }
        }
      </ul>
    </div>
  </div>
}
