<div class="cca-multi-file-upload cca-multi-file-upload--enhanced">
  <label [for]="id" [class]="currentLabelSize" id="{{ 'l.' + id }}">{{ label() }}</label>

  @if (text()) {
    <span>{{ text() }}</span>
  }

  @if (showFilesizeHint()) {
    <span [id]="id + '-hint-file-size'" class="govuk-hint">
      Your document must be a DOC, DOCX, XLS, XLSX, PPT, PPTX, VSD, VSDX, JPG, JPEG, PDF, PNG, TIF, TXT, DIB, BMP or CSV
      file, no more than 20MB.
    </span>
  }

  @if (hint()) {
    <span [id]="id + '-hint'" class="govuk-hint govuk-!-display-block">{{ hint() }}</span>
  }

  @if (shouldDisplayErrors) {
    <govuk-error-message [errors]="control.errors" [identifier]="id"> </govuk-error-message>
  }

  <input
    #input
    (blur)="onFileBlur()"
    (change)="onFileChange($event)"
    [accept]="accepted()"
    [class.govuk-file-upload--error]="shouldDisplayErrors"
    [disabled]="isDisabled"
    [id]="id"
    [name]="id"
    class="govuk-file-upload"
    type="file"
  />

  <cca-file-upload-list
    [listTitle]="listTitle()"
    (fileDelete)="onFileDeleteClick()"
    [files]="uploadedFiles$ | async"
    [isDisabled]="isDisabled"
  />
</div>
