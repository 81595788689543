<h3 class="govuk-heading-m">Users automatically notified</h3>

@for (user of defaultUsers(); track user.email) {
  <p class="govuk-!-margin-bottom-1 govuk-!-margin-top-0">
    {{ user.firstName }} {{ user.lastName }}, {{ user.type | noticeRecipientsType }}, {{ user.email }}
  </p>
}

@if (additionalUsers(); as additionalUsers) {
  <div
    formControlName="additionalUsersNotified"
    legend="Select any additional users you want to notify"
    legendSize="medium"
    class="govuk-checkboxes"
    govuk-checkboxes
  >
    @for (user of additionalUsers; track user.email) {
      <govuk-checkbox
        [value]="user"
        [label]="user.firstName + ' ' + user.lastName + ', ' + (user.type | noticeRecipientsType) + ', ' + user.email"
      ></govuk-checkbox>
    }
  </div>
}

@if (caExternalContacts(); as caExternalContacts) {
  <div
    formControlName="externalContactsNotified"
    legend="Select the external contacts you want to notify"
    legendSize="medium"
    class="govuk-checkboxes"
    govuk-checkboxes
  >
    @for (contact of caExternalContacts; track contact.id) {
      <govuk-checkbox [value]="contact.id" [label]="contact.email"></govuk-checkbox>
    }
  </div>
}

<div
  [options]="transformedRegulatorAuthorities()"
  formControlName="signatory"
  govuk-select
  label="Select the name and signature that will be shown on the official notice document"
></div>
